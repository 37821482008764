import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

// Get Activity
const activityGet = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.get(
    `${BASE_URL}/admin/activities/${data.businessId}?page=${data.pageno}&perPage=${data.limit}&userName=${data.userName??''}&type=${data.type??[]}`,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Get Activity api failed"); 
};

// Like/unLike Toggle
const activityLikeToggle = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/activity`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Activity Like Toggle api failed"); 
};

// add comment
const activityAddComment = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.post(
    `${BASE_URL}/admin/activity/comment`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Activity Comment api failed"); 
};

// update comment
const activityUpdateComment = async (token,commentId,data) => { 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.put(
    `${BASE_URL}/admin/activity/${commentId}`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Updated Activity Comment api failed"); 
};

// delete comment
const activityDeleteComment  = async (token,commentId,data) => {  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };  
  const response = await axios.delete(
    `${BASE_URL}/admin/activity/${commentId}`,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Delete Activity Comment api failed"); 
};

// Get Single Task Activity
const taskActivityGet = async (token,taskId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.get(
    `${BASE_URL}/admin/activities/task/${taskId}`,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Get Single Task Activity api failed"); 
};

// Get Board Activity
const boardActivityGet = async (token,boardId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }; 
  const response = await axios.get(
    `${BASE_URL}/admin/activities/board/${boardId}`,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Get Board Activity api failed"); 
};


const activityServices = {
    activityGet,
    activityLikeToggle,
    activityAddComment,
    activityUpdateComment,
    activityDeleteComment,
    taskActivityGet,
    boardActivityGet
};

export default  activityServices;
